/* -----inner-admin logo------------ */
.menu_btns {
  display: none;
}

.striking-logo img {
  max-width: 110px !important;
}

/* -----login-screen css------------ */
.login_center {
  background: #e5e5e5;
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
}

.login_card {
  background: #fff;
  min-width: 490px;
  padding: 50px;
  border-radius: 10px;
}

.login_card h1 {
  text-align: center;
  color: #333333;
  font-weight: 700;
  padding-bottom: 20px;
}

.login_card input::placeholder {
  color: #808080;
  font-size: 16px;
}

.login_card input {
  color: #808080;
  font-size: 16px;
}

.login_card .forgot-pass-link {
  display: table;
  margin: 30px auto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: #4f35ba;
  letter-spacing: 0px;
  text-align: center;
  text-decoration: revert;
}

.login_logo img {
  width: 200px;
  text-align: center;
  display: table;
  margin: 0px auto 45px;
}

.right_profile {
  display: flex;
  justify-content: center;
  align-items: center;
}

.right_profile p {
  margin: 0;
  font-size: 14px;
  font-weight: 900;
}

.imgTest {
  /* position: absolute; */
  /* width: 500px; */
  /* height: 500px; */
}

.imgTest img {
  /* width: 100%; */
}

.ck-rounded-corners .ck.ck-dropdown .ck-dropdown__panel .ck-list,
.ck.ck-dropdown .ck-dropdown__panel .ck-list.ck-rounded-corners {
  height: 150px;
  overflow: auto;
}

.react-draggable {
  width: max-content;
}

.artlistImg img {
  height: 250px;
  object-fit: cover;
  width: 100%;
}

.artlistImg {
  margin-bottom: 15px;
  min-height: 440px;
}

.viewImg img {
  width: 200px;
  height: 200px;
  object-fit: cover;
}

.viewImg {
  position: relative;
  width: 200px;
}

.viewImg button {
  position: absolute;
  top: 0;
  right: 0;
  background: #f00;
  border: none;
  border-radius: 50px;
  height: 30px;
  width: 30px;
  cursor: pointer;
}

.viewImg button svg {
  fill: #fff;
}




.viewDataImg button svg {
  fill: #fff;
}

.viewDataImg button {
  position: absolute;
  top: 0;
  right: 0;
  background: #f00;
  border: none;
  border-radius: 50px;
  height: 30px;
  width: 30px;
  cursor: pointer;
}

/* .mediaBtnFlex{
  display: flex;
} */
.mediaBtnFlex button {
  margin: 0 10px;
}

.video_tags {
  margin: 20px;
}

.artListDelete {
  position: relative;
}

.artListDelete a {
  position: absolute;
  top: 0;
  right: 0;
  background: #5f63f2;
  padding: 9px;
  color: #fff;
  border-bottom-left-radius: 5px;
}

.editeBtnTxt span {
  background: #f00;
  color: #fff;
  padding: 6px;
  border-radius: 520px;
}

.subscription_Ui h2 {
  font-size: 16px;
  text-align: center;
  margin: 0;
}

.subscription_Ui p {
  font-size: 16px;
  text-align: center;
}

.subscription_Ui h3 {
  font-size: 20px;
  text-align: center;
  font-weight: 700;
}

.subscription_Ui h4 {
  text-align: center;
}

.subscription_Ui a {
  text-align: center;
  display: table;
  background: #bc1e4a;
  padding: 4px 26px;
  margin: 22px auto 10px;
  color: #fff;
  border-radius: 4px;
}

.EditeArmarker {
  background: #ed516e;
  padding: 7px 30px;
  display: inline-block;
  color: #fff;
  margin: 10px 0;
  border-radius: 4px;
}

.ActivateBtnArmarker {
  background: #413e9d;
  padding: 7px 30px;
  display: inline-block;
  color: #fff;
  margin: 10px 0;
  border-radius: 4px;
}

.status_manage {
  padding: 0;
  margin: 0;
}

.status_manage h2 {
  font-size: 14px;
  font-weight: 700;
  margin: 0 0 0;
}

.ant-menu-item-selected svg {
  color: #fff !important;
}

.custom-card>.ant-card-body {
  height: 550px !important;
}



.container .card {
  position: relative;
  width: 400px;
  height: 350px;
  background: linear-gradient(#141484, #141484 30%, #1d3548 30%, #1d3548);
  margin: 10px;
  border-radius: 20px;
  overflow: hidden;
}

.container .card .imgbox {
  position: absolute;
  top: 10%;
  left: 25%;
  width: 50%;
  height: 50%;
  transition: 0.5s;
  /* z-index: 100000; */

  transform-origin: top;
  border-radius: 20px;


}

.container .card:hover .imgbox {
  /* transform: translateY(30px)scale(0.5); */
}

.container .card .imgbox img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}

.container .card .content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 30px;
  /* transform: translateY(100%); */
  /* transition: 1s; */

}

.container .card:hover .content {
  /* transform: translateY(0); */

}

.container .card .content .details {

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}

.container .card .content .details h2 {
  color: aliceblue;
  font-size: 20px;
  font-weight: 500;


}

.container .card .content .details h2 span {
  font-size: 0.8em;
  font-weight: 400;
  color: #03a9f4;
}

.container .card .content .details p {
  font-size: 10px;
  ;
  font-weight: 400;
  color: #03a9f4;
}

.social-icons {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: start;
  /* margin: 5px; */
}


.social-icons a {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #141484;
  border-radius: 50%;
  font-size: 1.5em;
  color: white;
  text-decoration: none;
  transition: 0.5s;


}

.social-icons a:hover {
  transform: rotate(360deg);

}

.btn {
  position: relative;
  height: 30px;
  width: 40px;
  background-color: antiquewhite;
  border-radius: 20px;


}

.cc {
  position: relative;
  background-color: black;
  height: 345px;
  width: 45px;
  border-radius: 50%;
  margin-left: -20px;
}



/* product */
.p_layout {
  margin: 30px
}



/* -------------------------------------------AR List css--------------------------------------- */
.night_effect {
  margin: auto;
  font-size: 20px;
  font-weight: 600;
  animation: lights 5s 750ms linear infinite;
}

.first_line {
  margin-bottom: 20px;
}

.card_icon {
  font-size: 25px;
  margin-right: 10px;
  margin-top: 10px;
}



.flip-card {
  background-color: transparent;
  width: 300px;
  height: 320px;

  border: 1px solid #f1f1f1;
  perspective: 1000px;
  /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  background-color: #bbb;
  color: black;
}

.flip-card-front img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

/* Style the back side */
.flip-card-back {
  background-color: white;
  color: white;
  transform: rotateY(180deg);
}

/* page */
.wrapper {
  height: 100vh;
  width: 100%;
  text-align: center;
  padding: 0px 0;
  background: #EBF0F5;
}

.wrapper .card h1 {

  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 10px;
}

.wrapper .card p {
  color: #404F5E;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  margin: 0;
}

.wrapper .card i {
  color: #9ABC66;
  font-size: 100px;
  /* line-height: 200px; */
  margin-left: -15px;
}

.wrapper .card {
  background: white;
  padding: 60px;
  border-radius: 4px;
  box-shadow: 0 2px 3px #C8D0D8;
  display: inline-block;
  margin: 0 auto;
  width: 100%;
  height: 100%;
}

.edit-tooltip {
  size: 20px;
  position: absolute;
  top: 40px;
  right: 26px;
  color: blue
}

.edit-tooltip-webar {
  size: 20px;
  position: absolute;
  top: 40px;
  left: 30px;
  color: blue
}

.edit-tooltip-webar-progress {
  size: 20px;
  position: absolute;
  top: -7px;
  left: -105px;
  color: blue;
}

.icon_container .edit:hover {
  cursor: pointer;
  color: rgb(62, 159, 238) !important;
}

.icon_container .delete:hover {
  cursor: pointer;
  color: rgb(62, 159, 238) !important
}

.RejectedTxt{
  color: red;
  font-weight: 500;
  cursor: pointer;
}



.image-text-container {
  width: 500px;
  /* background-color: grey; */
  /* position: absolute ; */
  word-wrap: break-word;
  overflow-wrap: break-word;
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to right, grey 2px, transparent 2px) 0 0,
    linear-gradient(to right, grey 2px, transparent 2px) 0 100%,
    linear-gradient(to left, grey 2px, transparent 2px) 100% 0,
    linear-gradient(to left, grey 2px, transparent 2px) 100% 100%,
    linear-gradient(to bottom, grey 2px, transparent 2px) 0 0,
    linear-gradient(to bottom, grey 2px, transparent 2px) 100% 0,
    linear-gradient(to top, grey 2px, transparent 2px) 0 100%,
    linear-gradient(to top, grey 2px, transparent 2px) 100% 100%;
  background-repeat: no-repeat;
  background-size: 50px 50px;
}

.viewDataImg {
  background: blue;
  /* object-fit: cover; */
  width: 90%;
  height: 90%;
}

.viewDataImg img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}


.scan-overlay {
  position: absolute;
  top: 200px;
  left: 270px;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 10px;
  font-size: 20px;
}

.child_component img {
  height: 350px;
  width: 400px;
}

.child_component video {
  height: 350px;
  width: 400px;
}

.child_component audio {
  /* height:350px; */
  width: 400px;
}

.scan-overlay::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.3);
}

.image_sider_container {
  /* margin-top:  30px; */
  width: 100%;
  height: 100%;
}

.text-controller-vc {
  display: flex;
  justify-content: center;
}

.user-card-inside {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  /* width: 100%;
  height: 100%; */
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 0 5px #ccc;
}

.user-avatar {
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 20px;
}

.user-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* .user-info {
  flex-grow: 1;
} */

.user-name {
  font-size: 24px;
  /* margin-bottom: 5px; */
}

.user-email-inside {
  color: #666;
  margin-left: 20px;
  /* margin-top: 9px; */
  /* margin-bottom: 10px; */
}










.btn_width {
  /* width: 60px,  !important; */
}





/* CSS */
.button-20 {
  appearance: button;
  background-color: #4D4AE8;
  background-image: linear-gradient(180deg, rgba(255, 255, 255, .15), rgba(255, 255, 255, 0));
  border: 1px solid #4D4AE8;
  border-radius: 10px;
  box-shadow: rgba(255, 255, 255, 0.15) 0 1px 0 inset, rgba(46, 54, 80, 0.075) 0 1px 1px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: Inter, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  margin: 0;
  padding: .5rem 1rem;
  text-align: center;
  text-transform: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
}

.button-20:focus:not(:focus-visible),
.button-20:focus {
  outline: 0;
}

.button-20:hover {
  background-color: #3733E5;
  border-color: #3733E5;
}

.button-20:focus {
  background-color: #413FC5;
  border-color: #3E3BBA;
  box-shadow: rgba(255, 255, 255, 0.15) 0 1px 0 inset, rgba(46, 54, 80, 0.075) 0 1px 1px, rgba(104, 101, 235, 0.5) 0 0 0 .2rem;
}

.button-20:active {
  background-color: #3E3BBA;
  background-image: none;
  border-color: #3A38AE;
  box-shadow: rgba(46, 54, 80, 0.125) 0 3px 5px inset;
}

.button-20:active:focus {
  box-shadow: rgba(46, 54, 80, 0.125) 0 3px 5px inset, rgba(104, 101, 235, 0.5) 0 0 0 .2rem;
}

.button-20:disabled {
  background-image: none;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.ant-layout-sider-children>div>div {
  overflow: inherit !IMPORTANT;
}

.ant-input-group-wrapper {
  display: inline-block;
  width: 100%;
  text-align: start;
  vertical-align: top;
}

button.ant-btn.ant-btn-default.ant-btn-icon-only.ant-input-search-button {
  color: white !important;
  ;
  background-color: #4D4AE8;

}

.btn_width {
  /* width: 155px!important; */
}

.WebArEditBtnDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.WebArbtn_width {
  margin-right: 50px;
}



.countdown-timer {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.countdown-timer .group {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.countdown-timer .value {
  background-color: white;
  color: #4D4AE8;
  font-size: 20px;
  font-weight: bold;
  padding: 8px;
  text-align: center;
  border-radius: 4px;

}

.countdown-timer .unit {
  font-size: 14px;
  font-weight: bold;
}

/* vertical line */
.corner-border::after {
  content: '';
  position: absolute;
  background-color: pink;
  width: 3px;
  height: 60%;
  top: 0;
  left: -15px;

}

/* horizontal line */
.corner-border::before {
  content: '';
  position: absolute;
  background-color: pink;
  width: 20%;
  height: 3px;
  top: 0;
  left: -12px;

}

.int_layout {
  margin-top: 25px !important;
  margin-left: 25px !important;
  margin-bottom: 25px !important;
}

.heading_layout {
  font-size: 20px;
  font-weight: 600;
}

.metadata_align video {
  width: 400px;
  height: 350px
}

.metadata_align audio {
  width: 400px;
}

.TimerProgress .ant-progress-inner {
  width: 60px !important;
  height: 60px !important;
  font-size: 15px !important;
  font-weight: 700 !important;
}

.ant-progress-text {
  margin-left: 0px !important;
}

.TimerProgress .ant-progress-circle-path {
  stroke: rgb(79, 53, 186) !important;
}



@media screen and (max-width: 520px) {
  .image-text-container {
    width: 300px;
    height: 200px;
  }

  /* .scan-overlay{
    top:130px;
    left: 170px;
    font-size: 15px;
    padding: 5px;
  } */
  .child_component img {
    width: 250px;
    height: 250px;
  }

  .child_component video {
    width: 250px;
    height: 250px;
  }

  .child_component audio {
    width: 250px;
  }
}